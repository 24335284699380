.page {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-content {
  color: #FFFFFF;
  width: 70%;
  text-align: center;
  margin: auto;
}
@media screen and (max-width: 1500px) {
  .page-content {
    width: 95%;
  }
}