.log-content {
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  right: 15%;
  display: flex; /* Добавляем flex-контейнер для выравнивания элементов */
  align-items: center; /* Выравнивание по вертикали */
  .iconify {
    font-size: 2rem;
  }
}

.log-content a {
  opacity: 1;
  display: flex; /* Добавляем flex-контейнер для выравнивания элементов */
  align-items: center; /* Выравнивание по вертикали */
}

.log-content a:hover {
  background-color: #FFFFFF;
  color: rgba(17, 17, 20);
  cursor: pointer;
}

.log-content img {
  margin-right: 10px;
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden;
  width: 25px;
  height: 25px; /* Добавляем высоту, чтобы сделать изображение более толстым */
}
/* Добавляем медиа-запросы для изменения стилей на мобильных устройствах */
@media screen and (max-width: 1600px) {
  .log-content {
    right: 5%;
    top: 20px; /* Объединяем оба медиа-запроса для top */
  }
}

