.sidebar {
  position: sticky;
  top: 5rem;
}
.side-items li {
  width: 14rem;
  display: grid;
  list-style: none;
}
.checkbox-r {
  display: none;
}
@media screen and (max-width: 1050px) {
  .side-items li {
    width: 80%;
  }
  .rules input[type="checkbox"]:checked:checked ~ .side-items {
    transform: translateX(0);
  }
  .side-items {
    transform: translateX(-100%);
  }
  .label-container {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 5%;
    height: 100%;
    z-index: 5;
  }
  .menu-toggle {
    background-color: #FFFFFF;
    height: 120px;
    width: 4px;
    cursor: pointer;
  }
  .hamburger-line {
    background: #FFFFFF;
    transform: rotate(90deg);
  }
  .label-container .menu-toggle.active .hamburger-line:nth-child(2) {
    height: 3px; /* Уменьшаем высоту на 30% */
  }
}