@font-face {
  font-family: 'Minecraft';
  src: url("../../storage/fonts/MinecraftRegular-Bmg3.otf");
}
.di {
  color: #FFD700;
}
.pix {
  color: #00BFFF;
}
.logo {
  font-family: Minecraft;
  position: absolute;
  left: 15%;
}
.logo:hover {
  text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 5px #fff, 0 0 11px #00BFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF, 0 0 55px #00BFFF, 0 0 80px #00BFFF;
  transition: 0.1s;
  cursor: pointer;
}
@media screen and (max-width: 1600px) {
  .logo {
    left: 5%;
  }
}
@media screen and (max-width: 1050px) {
  .logo {
    right: 5%;
  }
}