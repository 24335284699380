.navbar-content a {
  font-family: 'Open Sans', sans-serif;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  opacity: 0.70;
  text-decoration: none;
  transition: all 0.3s ease;
}
.navbar-content a.active,
.navbar-content a.active:hover {
  background-color: #ffffff;
  opacity: 1;
  color: rgba(17, 17, 20);
}
.navbar-content a:hover {
  background-color: #ffffff;
  opacity: 1;
  color: rgba(17, 17, 20);
}
@media screen and (max-width: 1050px) {
  .navbar-content, .menu-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .navbar-content a {
    font-size: 1.5em;
  }
  .menu-items {
    background-color: rgba(17, 17, 20, 0.9);
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 4;
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
}