.subscription a {
  color: #AFEEEE;
}
.subscription .page-content {
  width: 40%;
}
@media screen and (max-width: 1500px) {
  .subscription .page-content {
    width: 60%;
  }
}
@media screen and (max-width: 1050px) {
  .subscription .page-content {
    width: 95%;
  }
}