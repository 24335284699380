footer {
  background-color: #15151a;
  justify-content: space-evenly;
  display: flex;
  flex: 0 0;
  width: 100%;
}
h4 {
  font-size: 1.3rem;
  color: #ffffff;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
}
.get-help {
  display: inline;
  padding-left: 1rem;
}
.get-help a {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #ffffff;
  text-decoration: none;
  color: #bbbbbb;
  transition: all 0.3s ease;
  margin: 0 1rem 1rem 0;
}
.get-help a:hover {
  cursor: help;
  color: #ffffff;
}
.social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255,255,255,0.2);
  margin: 0 1rem 1rem 0;
  text-align: center;
  line-height: 55px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}
.iconify {
  justify-content: center;
  font-size: 1.5rem;
}
@media screen and (max-width: 900px) {
  footer {
    display: block;
    text-align: center;
  }
}