header {
  flex: 0 0;
  text-align: center;
  transition: 0.3s ease;
  position: fixed;
  width: 100%;
  height: 4rem;
  top: 0;
  z-index: 6;
}
.checkbox {
  display: none;
}
.navbar {
  list-style-type: none;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.navbar-items {
  margin: auto;
  display: flex;
}
.navbar li {
  padding: 1.2rem;
}
@media screen and (max-width: 1050px) {
  .navbar-items li {
    display: grid;
    width: 80%;
  }
  .navbar li {
    padding: 0;
  }
  .navbar {
    width: 100%;
    display: flex;
    position: fixed;
  }
  .navbar:not(:has(.checkbox:checked)) header {
    height: 4rem;
  }
  .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }
  .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }
  .navbar input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
  input[type="checkbox"]:checked ~ .navbar-items {
    transform: translateX(0);
  }
  .navbar-items {
    transform: translateX(-120%);
  }
  .navbar .checkbox {
    display: block;
    position: absolute;
    height: 32px;
    width: 32px;
    top: 20px;
    left: 5%;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
    text-align: right;
  }
  .navbar .hamburger-lines {
    display: block;
    position: absolute;
    height: 26px;
    width: 32px;
    top: 20px;
    left: 5%;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .navbar .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    background: #FFFFFF;
  }
  .navbar .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }
  .navbar .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }
  .navbar .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }
}