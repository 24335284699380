.rules {
  padding-top: 15rem;
  min-height: 100%;
  display: flex;
  justify-content: center;
}
h2 {
  color: #E0FFFF;
}
.rules-container {
  width: 70%;
}
section {
  margin-top: -15rem;
  margin-right: auto;
  margin-left: auto;
  width: 60%;
  font-size: large;
  text-align: left;
  color: white;
}
section a {
  color: #AFEEEE;
}
@media screen and (max-width: 1600px) {
  .rules-container {
    width: 90%;
  }
}
@media screen and (max-width: 1050px) {
  section {
    margin-top: 5rem;
    width: 95%;
  }
}