@font-face {
  font-family: 'Minecraft_2';
  src: url("../../storage/fonts/F77-Minecraft.ttf");
}
.home h1 {
  color: #FFFFFF;
  font-size: 4rem;
  font-weight: 300;
}
.home p {
  color: #FFFFFF;
  font-size: 1.1rem;
}
.home a {
  margin: 1rem;
}
.text {
  text-align: center;
  color: #FFFFFF;
}
@media screen and (max-width: 1500px) {
  .home-navbar {
    justify-content: center;
    position: relative;
    display: flex;
  }
}
@media screen and (max-width: 1050px) {
  .home h1 {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 767px) {
  .home-navbar {
    flex-direction: column;
    display: flex;
  }
}