@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=Inter&family=Signika+Negative&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=Inter&family=Manrope:wght@500&family=Signika+Negative&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=Hind:wght@400;500&family=Inter&family=Manrope:wght@500&family=Signika+Negative&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:600');

* {
  box-sizing: border-box;
}
body, html, #root, .App {
  font-family: 'Hind', sans-serif;
  background-color: rgba(17, 17, 20);
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  margin: 0;
  bottom: 0;
}
@media only screen and (max-width: 767px) {
  body, html, #root, .App {
    min-height: 600px;
    min-width: 395px;
  }
}
h1, h2, h3, h4 {
  font-family: 'Open Sans', sans-serif;
}