.button {
  text-decoration: none;
  justify-content: center;
  position: relative;
  display: inline-flex;
  padding: 0.6rem 2rem;
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #ffffff;
  background-color: rgba(255,255,255,0.2);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.button:hover::before {
  transform: translate(-50%, -50%) scale(20);
  opacity: 0;
}
.red:hover {
  background-color: #dc143c;
}
.blue:hover {
  background-color: #5865f2;
}
.green:hover {
  background-color: #228B22;
}
.white:hover {
  background-color: #ffffff;
}